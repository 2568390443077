import React, { Suspense, lazy, useEffect, useState } from "react";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";
import Login from "./components/SignIn";
import VerifyOTP1 from "./components/VerifyOtp1";
import VerifyOTP from "./components/VerifyOtp";
import AddUrls from "./components/AddUrls";
import UpdateUrls from "./components/UpdateUrls";
import RemoveUrls from "./components/RemoveUrls";
import ServiceAccount from "./components/ServiceAccount";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Register from "./components/Register";
import Pricing from "./components/Pricing";
import Banner from "./components/Banner";
import useSWR from "swr";
import { getAllserviceAccounts } from "./api";

const Dashboard = lazy(() => import("./components/Dashboard"));
const Websites = lazy(() => import("./components/Websites"));
const ViewPages = lazy(() => import("./components/ViewPages"));
const IndexReport = lazy(() => import("./components/IndexReport"));
const ConsoleReport = lazy(() => import("./components/ConsoleReport"));
const Profile = lazy(() => import("./components/Profile"));

function App() {
  const navigate = useNavigate();
  const { token, user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!token && window.location.path === "/") {
      navigate("/");
    } else if (token && window.location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [token]);

  const renderLoader = () => (
    <div className="flex items-center justify-center h-screen">
      <div aria-label="Loading..." role="status">
        <svg
          className="animate-spin w-6 h-6 fill-slate-800"
          viewBox="3 3 18 18"
        >
          <path
            className="opacity-20"
            d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
          ></path>
          <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
        </svg>
      </div>
    </div>
  );

  return (
    <Suspense fallback={renderLoader()}>
      <div>
        <div className="">
          {!token ? (
            <div className="">
              {window.location.pathname === "/pricing" ? (
                <div>
                  <div className="flex flex-col gap-5">
                    <Navbar />
                  </div>
                  <div className="2xl:px-28 2xl:py-5 xl:px-28 xl:py-5 lg:px-20 lg:py-5 md:px-10 md:py-5 px-3 py-3">
                    <Routes>
                      {/* <Route
                        path="/pricing"
                        element={<Pricing toast={toast} />}
                      /> */}
                    </Routes>
                  </div>
                </div>
              ) : (
                <div className="2xl:px-28 2xl:py-5 xl:px-28 xl:py-5 lg:px-20 lg:py-5 md:px-10 md:py-5 px-3 py-3">
                  <Routes>
                    <Route
                      path="/sign-up"
                      element={<Register toast={toast} />}
                    />
                    <Route path="/" element={<Login toast={toast} />} />
                    <Route
                      path="/verify-otp"
                      element={<VerifyOTP toast={toast} />}
                    />
                    <Route
                      path="/verify"
                      element={<VerifyOTP1 toast={toast} />}
                    />
                  </Routes>
                </div>
              )}
            </div>
          ) : (
            <div>
              {window.location.pathname === "/service/new" ? (
                <div className="2xl:px-28 2xl:py-5 xl:px-28 xl:py-5 lg:px-20 lg:py-5 md:px-10 md:py-5 px-3 py-3 ">
                  <Routes>
                    <Route path="/service/new" element={<ServiceAccount />} />
                  </Routes>
                </div>
              ) : (
                <>
                  <div>
                    <Navbar />
                    <div className="2xl:px-28 2xl:py-5 xl:px-28 xl:py-5 lg:px-20 lg:py-5 md:px-10 md:py-5 px-3 py-3">
                      <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />

                        <Route path="/websites" element={<Websites />} />
                        <Route
                          path="/websites/add-urls/:id"
                          element={<AddUrls />}
                        />
                        <Route
                          path="/websites/update-urls/:id"
                          element={<UpdateUrls />}
                        />
                        <Route
                          path="/websites/remove-urls/:id"
                          element={<RemoveUrls />}
                        />
                        <Route
                          path="/websites/view-pages/:id"
                          element={<ViewPages />}
                        />

                        <Route
                          path="/console-report"
                          element={<ConsoleReport />}
                        />
                        <Route path="/index-report" element={<IndexReport />} />
                        <Route path="/profile" element={<Profile />} />
                      </Routes>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <ToastContainer />
      </div>
    </Suspense>
  );
}

export default App;
