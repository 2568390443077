import axios from "axios";
import { BASE_URL } from "./BASE_URL";

export const handleCreateUser = async (
  name,
  email,
  image,
  password,
  mobile,
  websiteUrl
) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/auth/newuser`, {
      name,
      email,
      image,
      password,
      mobile,
      websiteUrl,
    });

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// verify-otp

export const handleVerifyOTP = async (email, userOTP) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/auth/verify-otp`, {
      email,
      userOTP,
    });

    console.log(res);

    return res;
  } catch (error) {
    return error;
  }
};

//  resend OTP

export const handleResendOTp = async (email) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/auth/resend-otp`, {
      email,
    });

    return res;
  } catch (error) {
    return error;
  }
};

// login user

export const userLogin = async (email, password) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/auth/login`, {
      email,
      password,
    });

    return res;
  } catch (error) {
    return error;
  }
};

// create new service account

export const handleCreateServiceAccount = async (
  serviceAccount,
  id,
  siteURL
) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/api/serviceaccount/createserviceaccount`,
      {
        serviceAccount,
        id,
        siteURL,
      }
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

// get user by id

export const handleGetUserById = async (id) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/auth/user/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

// sync websites

export const handleSyncWebsites = async (serviceAccount, websiteUrl) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/websites/syncwebsites`, {
      serviceAccount,
      websiteUrl,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

// get all service accounts

export const getAllserviceAccounts = async (id) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/api/serviceaccount/serviceaccounts/${id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

// get all websites

export const allWebsites = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL}/api/websites/mywebsites/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

// get website by id

export const getWebsiteById = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL}/api/websites/website/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

//  upload urls

export const UploadUrls = async (
  urls,
  createdBy,
  UploadedFileType,
  createdWebsite,
  domain
) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/websites/uploadurls`, {
      urls,
      createdBy,
      UploadedFileType,
      createdWebsite,
      domain,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

// get all upolads by user

export const allUploads = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL}/api/websites/allUploads/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const handleindexUrls = async (
  serviceAccount,

  userId,
  websiteId
) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/websites/indexurls`, {
      serviceAccount,

      userId,
      websiteId,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

// create console report

export const createConsoleReport = async (
  serviceAccount,
  siteurl,
  selectedId,
  sessionId
) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/api/consolereports/consolereport`,
      {
        serviceAccount,
        siteurl,
        selectedId,
        sessionId,
      }
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

// get all console report to particular user

export const handlegetConsoleReport = async (id) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/api/consolereports/getAllconsoleReports/${id}`
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

//  url inspection

export const handleURLInspection = async (serviceAccount, urls, siteurl) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/api/consolereports/urlinspection`,
      {
        serviceAccount,
        urls,
        siteurl,
      }
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

// update profile

export const handleUpdateProfile = async (formdata, id) => {
  console.log(formdata);
  try {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const res = await axios.put(
      `${BASE_URL}/api/auth/updateUserProfile/${id}`,
      formdata
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

// change password

export const handleChangePassword = async (id, password, newpassword) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/auth/changepassword/${id}`, {
      password,
      newpassword,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
