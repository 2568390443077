import React, { useEffect, useState } from "react";
import FileUpload from "./FileUpload";

import {
  getAllserviceAccounts,
  handleCreateServiceAccount,
  handleGetUserById,
} from "../api";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { CanonicalURL } from "../BASE_URL";

const ServiceAccount = () => {
  const [JSONData, setJSONData] = useState();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [serviceAccount, setServiceAccounts] = useState([]);
  const navigate = useNavigate();

  const fetcher = async () => {
    const response = await handleGetUserById(user?._id);

    return response?.data;
  };

  const { data, error, isLoading, isValidating } = useSWR("user", fetcher);

  const createServiceAccount = async () => {
    setLoading(true);
    try {
      if (data) {
        const res = await handleCreateServiceAccount(
          JSONData,
          user?._id,
          data?.websiteUrl
        );

        if (res.status === 200) {
          toast.success(res?.data?.message, {
            position: "top-right",
          });

          navigate("/websites", { state: { data: true } });
        } else {
          toast.error(res?.data?.error, {
            position: "top-right",
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center 2xl:h-screen xl:h-screen lg:h-screen flex-col">
      <Helmet>
        <title>Indexer Service Account</title>
        <meta
          name="description"
          content="Unlock the power of Indexer - your ultimate solution for automatic website indexing.Boost online visibility with our cutting-edge tools for rapid,efficient page indexing."
        />

        <link rel="canonical" href={`${CanonicalURL}/service/new`} />
      </Helmet>
      <div>
        <h1 className="font-serif antialiased tracking-wide leading-normal text-[24px] mt-10 flex items-center gap-3 flex-wrap ">
          <p>Connect Your Service Account for website </p>
          <p className="text-[#0099cc] antialiased tracking-normal text-[18px] mt-1 font-normal">{`[ ${data?.websiteUrl} ]`}</p>
        </h1>

        <div className="grid grid-cols-1 gap-6 mt-10 2xl:grid-cols-4 xl:grid-cols-4 md:grid-cols-1 lg:grid-cols-4 ">
          <div className="bg-white w-full border border-gray-200 rounded-[20px] 2xl:col-span-3 xl:col-span-3 lg:col-span-3 p-8 order-last 2xl:order-first xl:order-first lg:order-first md:order-last">
            <p className="font-semibold text-[22px] antialiased tracking-normal">
              JSON FILE
            </p>
            <p className="text-slate-400 antialiased tracking-normal">
              Please provide the JOSN file which you have downloaded from the
              Google cloud console.
            </p>

            <div className="mt-6">
              <FileUpload setJSONData={setJSONData} JSONData={JSONData} />

              <div className="mt-4">
                <button
                  disabled={loading || !JSONData}
                  onClick={createServiceAccount}
                  className={`bg-slate-700 text-white w-[200px] h-10 rounded-[8px] flex justify-center items-center  ${
                    (!JSONData || loading) && "opacity-60 "
                  }`}
                >
                  {loading ? (
                    <div aria-label="Loading..." role="status">
                      <svg
                        class="animate-spin w-6 h-6 fill-white"
                        viewBox="3 3 18 18"
                      >
                        <path
                          class="opacity-20"
                          d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                        ></path>
                        <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                      </svg>
                    </div>
                  ) : (
                    <p>Save changes</p>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white border border-gray-200 rounded-[20px] p-3 w-full">
            <p className="font-medium antialiased tracking-normal">
              To setup your service account and connect it with the Google
              search console.
            </p>

            <p className="mt-3">Please checkout this blog for the steps. </p>

            <div className="mt-4">
              <a
                onClick={() =>
                  window.open(
                    "https://autopagerank.com/blog/how-to-setup-google-service-account",
                    "_blank"
                  )
                }
                className="mt-6 underline cursor-pointer hover:text-blue-400"
              >
                Connect service account with search console
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceAccount;
